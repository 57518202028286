﻿// Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
// for details on configuring this project to bundle and minify static web assets.
import { gsap } from "gsap/dist/gsap";


window.addEventListener("load", () =>
{
   document.getElementById("mobile-button").addEventListener("click", OnMenuClick)

    var mobileMenuItems = Array.from(document.getElementsByClassName("nav-link-mobile"));
    mobileMenuItems.forEach(x => {
        x.addEventListener("click", OnMenuClick);
    })
});

function OnMenuClick()  {
    
    var cascadingItems = Array.from(document.getElementsByClassName("cascading-item"));
    
    cascadingItems.forEach(el =>{
        el.style.opacity = "0";
    });
    
    document.getElementById("navbar-default").classList.toggle("hidden");
    document.getElementById("mobile-background").classList.toggle("hidden");
    document.getElementById("navbar-default").classList.toggle("mobile-menu-drop-shadow");
    document.getElementById("close-mobile-menu").classList.toggle("hidden");
    document.getElementById("burger-icon").classList.toggle("hidden");
    document.getElementById("body").classList.toggle("overflow-hidden");
    
    gsap.to(".cascading-item", {opacity:1, stagger:0.1});
    
    
    if(document.getElementById("body").classList.contains("overflow-hidden"))
    {
        cascadingItems.forEach(el =>{
            el.style.opacity = "0";
        });
    }
    
    
};

